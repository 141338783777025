<template>
  <div>
    <div class="u-spacing-pt-xxl">
      <budgetPlannerHeader :step="step" />
    </div>
    <div
      class="u-flex-direction-column u-display-flex u-spacing-pr-xxl u-position-relative budget-planner-v2"
      :class="budgetPlanAutomationsTable.load && 'budget-plan-table-height'"
    >
      <Loader
        :loading="budgetPlanAutomationsTable.load"
        class="fill--parent"
        :color="'#3fadf7'"
        :thickness="'.2rem'"
      />
      <!-- Search Bar -->
      <div class="u-display-flex u-flex-direction-row">
        <form
          v-if="true"
          class="u-position-relative u-flex-direction-column u-display-flex u-spacing-mr-s u-spacing-mb-m u-width-480px"
          @submit.prevent="searchSubmitAutomations"
        >
          <rb-input
            v-model="searchAutomations"
            :placeholder="placeholderForSearch"
            data-cy="SearchInput"
            class="u-width-100 search-style search__input"
            :rounded="false"
          />
          <span
            class="u-position-absolute u-display-flex search-icon-bp-input"
            data-cy="submitBtn"
            @click="searchSubmitAutomations"
          >
            <rb-icon
              icon="search"
              class="search__input-icon rb-icon--medium"
              data-cy="searchIcon"
            />
          </span>
        </form>
        <div
          class="u-display-flex u-flex-direction-row u-flex-align-items-center u-spacing-mr-s u-spacing-mb-m u-spacing-ml-s"
        >
          <rb-icon
            :icon="'info-circle-outline'"
            class="u-color-grey-lighter rb-icon--medium u-cursor-pointer u-spacing-mr-xs u-spacing-mt-xs"
          />
          <span
            class="u-font-weight-bold u-font-size-6 u-color-grey-lighter u-flex-align-items-center u-spacing-mr-s u-spacing-mt-xs"
            >Note:
          </span>
          <span
            class="u-font-size-6 u-color-grey-lighter u-display-flex u-spacing-mt-xs u-flex-align-items-center"
            >It's not mandatory to have floor and ceiling value for bid and
            budgets
          </span>
        </div>
      </div>

      <div class="u-display-flex u-position-relative u-flex-direction-row">
        <!--Table-->
        <div
          class="bp-preview-table-container u-width-100 u-border-top u-border-right u-border-color-grey-xxx-light u-border-radius-3"
        >
          <rb-insights-table
            :key="tableKeyAutomations"
            class="budget-plan-table-height"
            :config="{}"
            :table-row="automationsSearchedTable"
            :table-column="columnsDef"
            :stop-editing-when-grid-loses-focus="true"
            :grid-options="gridOptions"
            :single-click-edit="true"
            :enable-cell-text-selection="true"
            :row-height="45"
            :custom-has-expand-column-order="1"
            :levels="levels"
            :has-expand="true"
            :expand-cell-width="65"
            :has-default-row-expand="false"
            :enable-client-side-sorting="false"
            :sorting-change-event="sortInputTableEventAutomations"
            :enable-server-side-sorting="true"
            table-id="budget-automations-master"
            :get-current-instance="getTableInstanceAutomations"
            @cellEditingStopped="automationsCellEditingStopped"
            @tableExpandChangeTriggered="onAutomationsRowExpand"
          />
        </div>
      </div>
    </div>

    <budgetPlannerFooter
      :show-left-btn="true"
      btn-right-text="Next"
      class="u-spacing-pt-xl"
      @onClickBtnRight="nextStep"
      @onClickBtnLeft="previousStep"
    />
  </div>
</template>
<script>
import Vue from 'vue';
import { cloneDeep } from 'lodash';
import { sortBudgetPlanInputRows } from '@/pages/budget-planner-v2/create-plan/store/helpers/transformers.js';
import {
  convertExecutionStrategyToTempRow,
  deleteKeysInRow
} from '@/pages/budget-planner-v2/create-plan/steps/inputBudgetPlan/helpers.js';
import { columns } from '@/pages/budget-planner-v2/create-plan/steps/automations/automations.js';
import budgetPlannerFooter from '@/components/ams/budgetPlanner/steps/common/footer.vue';
import budgetPlannerHeader from '@/components/ams/budgetPlanner/steps/common/header.vue';
import Loader from '@/components/basic/loader';
import { eventBus } from '@/utils/services/eventBus';
import {
  addNewConstraintRows,
  ignoreAGValues
} from '@/pages/budget-planner-v2/create-plan/steps/automations/helpers.js';

export default {
  components: {
    budgetPlannerHeader,
    budgetPlannerFooter,
    Loader
  },
  props: {
    step: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data() {
    return {
      gridOptions: {
        domLayout: 'normal',
        context: {
          componentParent: this
        },
        onCellEditingStopped: this.automationsCellEditingStopped,
        suppressScrollOnNewData: true
      },
      levels: [
        {
          level: 1,
          getter: 'budgetPlannerOmni/getBudgetPlanLevel1',
          action: 'budgetPlannerOmni/fetchBudgetPlanLevelData'
        }
      ],
      tempRowData: {},
      tableInstance: null,
      searchTextAutomations: '',
      sortInputTableEventAutomations: 'budgetOptimiserOmniSort',
      searchAutomations: '',
      tableKeyAutomations: 0,
      columnsDef: [],
      delimiter: '|-|'
    };
  },
  computed: {
    placeholderForSearch() {
      return 'Search for Profile or Portfolio';
    },
    automationsSearchedTable() {
      const searchkeyAutomations = this.searchTextAutomations
        ?.trim()
        .toLowerCase();
      let table = cloneDeep(this.budgetPlanAutomationsTable.rows);
      if (searchkeyAutomations.length) {
        table = table.filter((row) => {
          if (row.searchKey.includes(searchkeyAutomations)) {
            for (let key in row) {
              if (key.includes('ag-') && !ignoreAGValues.includes(key)) {
                delete row[key];
              }
            }
          }
          return row.searchKey.includes(searchkeyAutomations);
        });
      }
      return table;
    },
    budgetPlanAutomationsTable() {
      return this.$store.getters[
        'budgetPlannerOmni/getBudgetPlanInputTableRows'
      ];
    },
    getAutomationsTableSort() {
      return this.$store.getters['budgetPlannerOmni/getAutomationsTableSort'];
    },
    planFetchCountAutomations() {
      return this.$store.getters['budgetPlannerOmni/getPlanFetchCount'];
    },
    getAutomationsTableSortDirection() {
      return this.$store.getters[
        'budgetPlannerOmni/getAutomationsTableSortDirection'
      ];
    }
  },
  watch: {
    budgetPlanAutomationsTable: {
      handler(newValue) {
        if (newValue?.error) {
          this.$snackbar.open({
            message: 'Something went wrong',
            actionText: '',
            duration: 5000
          });
        }
      },
      deep: true
    },
    'tableInstance.localTableRow': {
      handler(newValue, oldValue) {
        let isExpanded = false;
        for (const row of newValue) {
          if (row.isExpanded) {
            isExpanded = true;
            break;
          }
        }
        if (isExpanded === this.isExpanded) {
          return;
        }
        this.isExpanded = isExpanded;
        this.updateAutomationsChildValues();
      },
      deep: true
    },
    planFetchCountAutomations: {
      handler() {
        const clonedPlan = cloneDeep(this.budgetPlanAutomationsTable.rows);
        convertExecutionStrategyToTempRow(clonedPlan, this.tempRowData);
      },
      immediate: true
    }
  },
  created() {
    this.columnsDef = columns();
    const that = this;
    eventBus.$on(that.sortInputTableEventAutomations, (column) => {
      console.log(Vue);
      that.$store.commit('budgetPlannerOmni/SORT_AUTOMATIONS_TABLE', {
        direction: column.sort,
        sort: column.colId
      });
      let clonedPlan = cloneDeep(that.budgetPlanAutomationsTable.rows);
      for (let row of clonedPlan) {
        deleteKeysInRow(row, ignoreAGValues);
        that.isExpanded = false;
      }
      clonedPlan = sortBudgetPlanInputRows(
        clonedPlan,
        that.getAutomationsTableSort,
        that.getAutomationsTableSortDirection,
        column.colId !== 'ui_label'
      );

      that.$store.dispatch(
        'budgetPlannerOmni/updateBudgetPlanInputTableRows',
        clonedPlan
      );
    });
  },
  destroyed() {
    eventBus.$off(this.sortInputTableEventAutomations);
  },
  mounted() {
    this.restoreAutomationsTempRows();
  },
  methods: {
    restoreAutomationsTempRows() {
      const clonedAutomationsPlan = cloneDeep(
        this.budgetPlanAutomationsTable.rows
      );
      this.convertAutomationsToTempRow(clonedAutomationsPlan);
    },
    checkForDataReset(isRowDisabledCheck, row) {
      let isDisabled = false;
      // child level && profile selected
      if (row.position === 1 && isRowDisabledCheck) {
        isDisabled = true;
      }

      // parent level && portfolio Selected
      if (
        row.position === 0 &&
        row.selectedLevelDropdown.key === 'portfolio_name' &&
        !isRowDisabledCheck
      ) {
        isDisabled = true;
      }
      return isDisabled;
    },
    convertAutomationsToTempRow(rows, isRowDisabledCheck = false) {
      const automationConstraints = [
        'bidFloor',
        'bidCeiling',
        'budgetFloor',
        'budgetCeiling'
      ];
      for (let row of rows) {
        for (let constraint of automationConstraints) {
          if (!this.tempRowData[row.name]) {
            this.tempRowData[row.name] = {};
            this.tempRowData[row.name].automationConstraints = {};
          }
          if (
            row.automationConstraints &&
            row.automationConstraints[constraint] >= 0 &&
            !this.checkForDataReset(isRowDisabledCheck, row)
          ) {
            this.tempRowData[row.name].automationConstraints[constraint] =
              row.automationConstraints[constraint];
          }
        }
        if (row?.nextLevel.length) {
          this.convertAutomationsToTempRow(
            row?.nextLevel,
            row.selectedLevelDropdown.key === 'profile_name'
          );
        }
      }
    },
    clearTempRowForAutomations(currentRowArg, currentColumnArg) {
      const { name } = currentRowArg;
      for (const key in this.tempRowData) {
        const parent = key.split(this.delimiter)?.[1] || null;
        if (parent === name) {
          this.tempRowData[key].automationConstraints[currentColumnArg] = null;
        }
      }
    },
    callPacingApiAutomations() {
      const isNextYearPlanAutomations = this.$route.query?.nextYearPlan;
      this.$store.dispatch('budgetPlannerOmni/formPacingPayload', {
        isNextYearPlan: !!isNextYearPlanAutomations,
        isAutomationsEnabled: true
      });
    },
    updateAutomationsChildValues(forceUpdate = true) {
      this.$nextTick(() => {
        let isValueUpdated = false;
        for (let row of this.tableInstance.localTableRow) {
          if (this.tempRowData[row.name]) {
            for (let key in this.tempRowData[row.name]) {
              row[key] = this.tempRowData[row.name].automationConstraints;
              isValueUpdated = true;
            }
          }
        }
        if (isValueUpdated && forceUpdate) {
          this.forceUpdateAutomations();
        }
      });
    },
    automationsCellEditingStopped(event, forceUpdate = true) {
      const currentRow = event.data;
      const currentColumn = event.colDef.field;
      const { name } = currentRow;
      if (!this.tempRowData[name]) {
        this.tempRowData[name] = {};
      }
      if (
        this.tempRowData[name].automationConstraints &&
        parseFloat(parseFloat(currentRow[currentColumn]).toFixed(2)) > 0
      ) {
        this.tempRowData[name].automationConstraints[currentColumn] =
          parseFloat(parseFloat(currentRow[currentColumn]).toFixed(2));
      }
      this.clearTempRowForAutomations(currentRow, currentColumn);
      this.updateAutomationsChildValues(forceUpdate);
    },
    nextStep() {
      this.updatePlanOnStepChangeForAutomations();
      this.callPacingApiAutomations();
      this.$emit('nextStep', {});
    },
    updatePlanOnStepChangeForAutomations(search = false) {
      const clonedAutomationsPlan = cloneDeep(
        this.budgetPlanAutomationsTable.rows
      );
      addNewConstraintRows(this.tempRowData, clonedAutomationsPlan);
      if (!search) {
        this.tempRowData = {};
      }
      this.$store.dispatch(
        'budgetPlannerOmni/updateBudgetPlanInputTableRows',
        clonedAutomationsPlan
      );
    },
    previousStep() {
      this.updatePlanOnStepChangeForAutomations();
      this.callPacingApiAutomations();
      this.$emit('prevStep', {});
    },
    forceUpdateAutomations() {
      this.tableInstance.localTableRow = cloneDeep(
        this.tableInstance.localTableRow
      );
    },
    rowSelected(automationsRow) {
      // we control the arrow from here
      const { ui_label } = automationsRow.params;
      this.levelName = automationsRow.params.levelName;
      this.selectedEntity = automationsRow.params.name
        .split(this.delimiter)
        .map((item) => (item === 'null' ? ui_label : item))
        .reverse()
        .join(' > ');
    },
    searchSubmitAutomations() {
      this.updatePlanOnStepChangeForAutomations(true);
      this.searchTextAutomations = this.searchAutomations;
    },
    getTableInstanceAutomations(thatRef) {
      this.tableInstance = thatRef;
    },
    onAutomationsRowExpand() {
      this.updateAutomationsChildValues();
    }
  }
};
</script>

<style scoped>
.search-style {
  background-color: white !important;
  border: 1px solid lightgray;
  border-radius: 5px;
}
</style>
