import { deleteKeysInRow } from '../inputBudgetPlan/helpers';

export const assignKeyInTempRowAutomations = (row, tempRowData) => {
  for (let key in tempRowData[row.name]) {
    row[key] = tempRowData[row.name].automationConstraints;
  }
};

export const navigateMonthsToFindData = (data) => {
  let isBudgetPresent = false;
  [
    'jan',
    'feb',
    'mar',
    'apr',
    'may',
    'jun',
    'jul',
    'aug',
    'sep',
    'oct',
    'nov',
    'dec'
  ].forEach((month) => {
    if (parseFloat(parseFloat(data[month]).toFixed(2)) > 0) {
      isBudgetPresent = true;
    }
  });
  return isBudgetPresent;
};

export const mapperConstraint = {
  budgetFloor: 'budgetCeiling',
  budgetCeiling: 'budgetFloor',
  bidFloor: 'bidCeiling',
  bidCeiling: 'bidFloor'
};

export const addNewConstraintRows = (tempRows, data) => {
  for (let row of data) {
    row.isExpanded = false;
    deleteKeysInRow(row, ignoreAGValues);
    if (tempRows[row.name] && tempRows[row.name].automationConstraints) {
      assignKeyInTempRowAutomations(row, tempRows);
    }
    if (row?.nextLevel?.length) {
      addNewConstraintRows(tempRows, row.nextLevel);
    }
  }
};

export const ignoreAGValues = ['ag-grid-has-expand', 'ag-grid-total-levels'];
