<template>
  <div
    :title="
      disableCell ? disableCell && cellEnabled.reason : 'Click to enter value'
    "
    class="u-display-flex u-width-100 u-height-100 u-flex-align-items-center u-flex-justify-content-center u-spacing-p-xs"
    :class="[
      disableCell ? 'cell-grey-color-bp2' : 'u-color-grey-base cell-hevor-bp2',
      cellValue && 'u-flex-justify-content-center'
    ]"
  >
    {{ cellValue ? currency : ''
    }}{{ cellValue ? numberFormatter(cellValue) : '-' }}
  </div>
</template>
<script>
import Vue from 'vue';
import { cloneDeep } from 'lodash';
import { getLocale } from '@/utils/helpers/formatter.js';

export default {
  props: {
    params: {
      type: Object,
      default: function () {
        return {};
      }
    },
    isCellEnabledAutomations: {
      type: Function,
      default: function () {}
    }
  },
  data() {
    return {
      currency: Vue.prototype.$currency
    };
  },
  computed: {
    cellValue() {
      let newValue = parseFloat(
        parseFloat(
          this.params.data?.automationConstraints?.[this.params?.colDef?.field]
        ).toFixed(2)
      );
      if (isNaN(newValue) || this.disableCell) {
        newValue = '';
      }
      return newValue;
    },
    cellEnabled() {
      return this.isCellEnabledAutomations(this.params, this);
    },
    disableCell() {
      return !this.cellEnabled.value;
    }
  },
  methods: {
    numberFormatter(value) {
      let newValue = cloneDeep(parseFloat(value).toFixed(2));
      newValue = parseFloat(parseFloat(newValue).toFixed(2));
      const finalVal = newValue?.toLocaleString(getLocale(), {
        style: 'decimal',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
      });
      return finalVal;
    }
  }
};
</script>
