<template>
  <div
    class="u-display-flex u-width-100 u-flex-justify-content-space-between u-height-100"
    @click="rowSelected"
  >
    <div
      :title="params.data.ui_label"
      class="u-color-grey-base u-text-overflow-ellipsis"
    >
      {{ params.data.ui_label }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    params: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  computed: {},
  methods: {
    rowSelected() {
      this.$emit('rowSelected', {
        params: this.params.data
      });
    }
  }
};
</script>
