import { formatter } from '@/utils/helpers/formatter.js';
import constraintCell from '@/pages/budget-planner-v2/create-plan/steps/automations/TableCells/constraintCell.vue';
import mainCell from '@/pages/budget-planner-v2/create-plan/steps/automations/TableCells/mainCell.vue';
import {
  navigateMonthsToFindData,
  mapperConstraint
} from '@/pages/budget-planner-v2/create-plan/steps/automations/helpers.js';

const isCellEnabledAutomations = (params, ref) => {
  const name = params?.data?.parentName || params?.data?.name;
  const dropdown =
    ref.$store.getters['budgetPlannerOmni/getDropdownSelectionMap']?.[name]
      .title;
  const isOnlyPortfolioLevelSelected =
    params?.data?.levelDropdown?.[0]?.title === 'Portfolio';
  const condition1 =
    params.data.position === 0 && // only check at level = 0
    params.data.finalBudgetPlan &&
    !params.data.finalBudgetPlan > 0;
  const condition2 =
    params.data.position === 0 && !navigateMonthsToFindData(params.data); // only check at level = 0
  const condition3 = dropdown === 'Profile' && params.data.position > 0;
  const condition4 =
    !isOnlyPortfolioLevelSelected &&
    dropdown === 'Portfolio' &&
    params.data.position === 0;
  let reason = '';
  let value = true;
  if (condition1 || condition2) {
    value = false;
    reason = 'Missing Final Budget Plan';
  } else if (condition3) {
    value = false;
    reason = 'Enter Automation Constraints at Parent Level only';
  } else if (condition4) {
    value = false;
    reason = 'Please enter campaign constraints on Child level';
  }
  return {
    value,
    reason
  };
};

const isValidConstraint = (data, automationConstraints, colName) => {
  let isValid = true;
  let msg = '';
  // negative check
  if (data < 0) {
    msg = 'Invalid value entered, value has been reset to `NA`';
    isValid = false;
  }
  // ceiling < floor
  if (
    data > 0 &&
    colName.includes('Ceiling') &&
    automationConstraints[mapperConstraint[colName]] &&
    data < automationConstraints[mapperConstraint[colName]]
  ) {
    msg = 'Ceiling cannot be lower than floor, value has been reset to ‘NA’';
    isValid = false;
  }
  // floor > ceiling
  if (
    data > 0 &&
    colName.includes('Floor') &&
    automationConstraints[mapperConstraint[colName]] &&
    data > automationConstraints[mapperConstraint[colName]]
  ) {
    msg = 'Floor cannot be greater than ceiling, value has been reset to ‘NA’';
    isValid = false;
  }
  return { msg, isValid };
};

export const columns = () => {
  return [
    {
      field: 'ui_label',
      headerComponentFramework: 'genericTableHeader',
      headerName: `Scope`,
      title: `Scope`,
      cellRendererFramework: 'DynamicCellComponent',
      pinned: true,
      headerComponentParams: {
        enableSorting: true,
        toolTipText: 'Scope',
        keyType: 'string'
      },
      cellStyle: (params) => {
        if (params?.data?.position > 0) {
          return { 'padding-left': '42px' };
        }
        return null;
      },
      suppressSizeToFit: false,
      minWidth: 150,
      cellRendererParams: {
        component: mainCell,
        eventMap: {
          rowSelected: 'rowSelected'
        },
        paramsToProps: (params) => {
          return {
            params
          };
        }
      },
      eventMap: {}
    },
    {
      field: 'bid',
      headerName: 'Bid',
      title: 'Bid',
      cellRendererFramework: 'DynamicCellComponent',
      headerComponentFramework: 'genericTableHeader',
      children: [
        {
          field: 'bidFloor',
          headerName: 'Floor',
          title: 'Floor',
          cellRendererFramework: 'DynamicCellComponent',
          headerComponentFramework: 'genericTableHeader',
          headerComponentParams: {
            enableSorting: false,
            keyType: 'string'
          },
          cellStyle: (params) => {
            const vueRef = params.context.componentParent;
            const cellEnabled = isCellEnabledAutomations(params, vueRef);
            const disableCellDropdownSelection = !cellEnabled.value;
            const commonStyle = { padding: '0px', margin: '0px' };
            if (disableCellDropdownSelection) {
              return { backgroundColor: '#f6f7fa', ...commonStyle };
            }
            return commonStyle;
          },
          editable: (params) => {
            const vueRef = params.context.componentParent;
            const cellEnabled = isCellEnabledAutomations(params, vueRef);
            return cellEnabled.value;
          },
          suppressSizeToFit: false,
          minWidth: 50,
          valueParser: (params) => {
            let constraintVal = parseFloat(
              parseFloat(params.newValue).toFixed(2)
            );
            if (isNaN(constraintVal)) {
              constraintVal = '';
            }
            const automationConstraints = params.data.automationConstraints;
            const colName = params.column.colId;
            const { msg, isValid } = isValidConstraint(
              constraintVal,
              automationConstraints,
              colName
            );
            if (!isValid) {
              const vueRef = params.context.componentParent;
              vueRef.$snackbar.open({
                message: msg,
                duration: 3000,
                buttonColor: '#f5d908',
                actionText: ''
              });
              return null;
            }
            return constraintVal;
          },
          cellRendererParams: {
            component: constraintCell,
            eventMap: {},
            paramsToProps: (params) => {
              return {
                params,
                isCellEnabledAutomations
              };
            }
          },
          eventMap: {}
        },
        {
          field: 'bidCeiling',
          headerName: 'Ceiling',
          title: 'Ceiling',
          cellRendererFramework: 'DynamicCellComponent',
          headerComponentFramework: 'genericTableHeader',
          headerComponentParams: {
            enableSorting: false,
            keyType: 'string'
          },
          cellStyle: (params) => {
            const vueRef = params.context.componentParent;
            const cellEnabled = isCellEnabledAutomations(params, vueRef);
            const disableCellDropdownSelection = !cellEnabled.value;
            const commonStyle = { padding: '0px', margin: '0px' };
            if (disableCellDropdownSelection) {
              return { backgroundColor: '#f6f7fa', ...commonStyle };
            }
            return commonStyle;
          },
          editable: (params) => {
            const vueRef = params.context.componentParent;
            const cellEnabled = isCellEnabledAutomations(params, vueRef);
            return cellEnabled.value;
          },
          suppressSizeToFit: false,
          minWidth: 50,
          valueParser: (params) => {
            let constraintVal = parseFloat(
              parseFloat(params.newValue).toFixed(2)
            );
            if (isNaN(constraintVal)) {
              constraintVal = '';
            }
            const automationConstraints = params.data.automationConstraints;
            const colName = params.column.colId;
            const { msg, isValid } = isValidConstraint(
              constraintVal,
              automationConstraints,
              colName
            );
            if (!isValid) {
              const vueRef = params.context.componentParent;
              vueRef.$snackbar.open({
                message: msg,
                duration: 3000,
                buttonColor: '#f5d908',
                actionText: ''
              });
              return null;
            }
            return constraintVal;
          },
          cellRendererParams: {
            component: constraintCell,
            eventMap: {},
            paramsToProps: (params) => {
              return {
                params,
                isCellEnabledAutomations
              };
            }
          },
          eventMap: {}
        }
      ],
      headerComponentParams: {
        enableSorting: false,
        keyType: 'string'
      },
      suppressSizeToFit: false,
      minWidth: 100,
      cellRendererParams: {
        keyType: 'string',
        formatterFn: formatter,
        eventMap: {
          rowSelected: 'rowSelected'
        },
        paramsToProps: (params) => {
          const level = params?.data?.['ag-grid-level'];
          if (level > 0) {
            return { 'padding-left': '28px' };
          }
          return null;
        }
      },
      eventMap: {}
    },
    {
      field: 'budget',
      headerName: 'Budget',
      title: 'Budget',
      cellRendererFramework: 'DynamicCellComponent',
      headerComponentFramework: 'genericTableHeader',
      children: [
        {
          field: 'budgetFloor',
          headerName: 'Floor',
          title: 'Floor',
          cellRendererFramework: 'DynamicCellComponent',
          headerComponentFramework: 'genericTableHeader',
          headerComponentParams: {
            enableSorting: false,
            keyType: 'string'
          },
          cellStyle: (params) => {
            const vueRef = params.context.componentParent;
            const cellEnabled = isCellEnabledAutomations(params, vueRef);
            const disableCellDropdownSelection = !cellEnabled.value;
            const commonStyle = { padding: '0px', margin: '0px' };
            if (disableCellDropdownSelection) {
              return { backgroundColor: '#f6f7fa', ...commonStyle };
            }
            return commonStyle;
          },
          editable: (params) => {
            const vueRef = params.context.componentParent;
            const cellEnabled = isCellEnabledAutomations(params, vueRef);
            return cellEnabled.value;
          },
          suppressSizeToFit: false,
          minWidth: 50,
          valueParser: (params) => {
            let constraintVal = parseFloat(
              parseFloat(params.newValue).toFixed(2)
            );
            if (isNaN(constraintVal)) {
              constraintVal = '';
            }
            const automationConstraints = params.data.automationConstraints;
            const colName = params.column.colId;
            const { msg, isValid } = isValidConstraint(
              constraintVal,
              automationConstraints,
              colName
            );
            if (!isValid) {
              const vueRef = params.context.componentParent;
              vueRef.$snackbar.open({
                message: msg,
                duration: 3000,
                buttonColor: '#f5d908',
                actionText: ''
              });
              return null;
            }
            return constraintVal;
          },
          cellRendererParams: {
            component: constraintCell,
            eventMap: {},
            paramsToProps: (params) => {
              return {
                params,
                isCellEnabledAutomations
              };
            }
          },
          eventMap: {}
        },
        {
          field: 'budgetCeiling',
          headerName: 'Ceiling',
          title: 'Ceiling',
          cellRendererFramework: 'DynamicCellComponent',
          headerComponentFramework: 'genericTableHeader',
          headerComponentParams: {
            enableSorting: false,
            keyType: 'string'
          },
          cellStyle: (params) => {
            const vueRef = params.context.componentParent;
            const cellEnabled = isCellEnabledAutomations(params, vueRef);
            const disableCellDropdownSelection = !cellEnabled.value;
            const commonStyle = { padding: '0px', margin: '0px' };
            if (disableCellDropdownSelection) {
              return { backgroundColor: '#f6f7fa', ...commonStyle };
            }
            return commonStyle;
          },
          editable: (params) => {
            const vueRef = params.context.componentParent;
            const cellEnabled = isCellEnabledAutomations(params, vueRef);
            return cellEnabled.value;
          },
          suppressSizeToFit: false,
          minWidth: 50,
          valueParser: (params) => {
            let constraintVal = parseFloat(
              parseFloat(params.newValue).toFixed(2)
            );
            if (isNaN(constraintVal)) {
              constraintVal = '';
            }
            const automationConstraints = params.data.automationConstraints;
            const colName = params.column.colId;
            const { msg, isValid } = isValidConstraint(
              constraintVal,
              automationConstraints,
              colName
            );
            if (!isValid) {
              const vueRef = params.context.componentParent;
              vueRef.$snackbar.open({
                message: msg,
                duration: 3000,
                buttonColor: '#f5d908',
                actionText: ''
              });
              return null;
            }
            return constraintVal;
          },
          cellRendererParams: {
            component: constraintCell,
            eventMap: {},
            paramsToProps: (params) => {
              return {
                params,
                isCellEnabledAutomations
              };
            }
          },
          eventMap: {}
        }
      ],
      headerComponentParams: {
        enableSorting: false,
        keyType: 'string'
      },
      suppressSizeToFit: false,
      minWidth: 100,
      cellRendererParams: {
        keyType: 'string',
        formatterFn: formatter,
        eventMap: {
          rowSelected: 'rowSelected'
        },
        paramsToProps: (params) => {
          const level = params?.data?.['ag-grid-level'];
          if (level > 0) {
            return { 'padding-left': '28px' };
          }
          return null;
        }
      },
      eventMap: {}
    }
  ];
};
